import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import {
  Alert,
  Card,
  CardFooter,
  SurveyQuestion,
  FileHandle,
  Text,
  SubQuestion,
  SideDrawer,
  Modal,
  Flex,
  Button,
  Expandable,
  Checkbox,
  Color,
} from 'component-library';
import {
  AnswerType,
  AnswerValueType,
} from 'component-library/dashboard/surveyquestion/SurveyQuestion';
import { SaveAnswersResponse } from 'lib/useSurveyQuestions';
import {
  CmsQuestionData,
  MSClientResponse,
  Document,
  ProgramData,
  DocumentTypeEnum,
} from 'lib/interfaces';
import { Auth0FeatureContext } from 'components/util/Auth0Feature';
import { CompanyContext } from 'pages/CompanyRequired';
import TotalIncomeGrossReceiptsHelpDrawer from './TotalIncomeGrossReceiptsHelpDrawer';
import AttentionNeeded from '../../../../../components/icons/AttentionNeeded';
import {
  FileTypes,
  GraphCmsQuestionIdEnum,
  Programs,
  taxInfoQuestionIds,
} from 'lib/constants';
import { isFileSizeWithinLimits, isFileEncrypted } from 'lib/helpers';
import { useCommonStores } from 'stores/useStores';
import { taxYearFilteringTaxReturnUpload } from '../../../../../utils/hygraphShowConditionUtils';

const useStyles = makeStyles(() => ({
  manualInputField: {
    '& > div': {
      width: '100%',
      maxWidth: '480px',
    },
  },
  uploadedDocs: {
    borderLeft: `1px solid ${Color.neutral._20}`,
    borderRight: `1px solid ${Color.neutral._20}`,
    borderTop: `1px solid ${Color.neutral._20}`,
    '&:nth-child(2)': {
      borderTop: 'none',
    },
  },
}));

const GENERIC_VISUAL_ERROR = `Something went wrong, please try again`;
const UPLOAD_FILE_SIZE_LIMIT = 1024 * 1024 * 20;

interface TaxReturnUploadQuestionProps {
  onComplete: (question: CmsQuestionData) => void;
  program: ProgramData;
  getQuestionProps: CmsQuestionData;
  setEditMode: (editMode: boolean) => void;
  saveAnswers?: () => Promise<SaveAnswersResponse>;
  documents?: Document[];
}

interface EncryptedFileProps {
  questionId: string | null;
  fileHandle: FileHandle | null;
}

const TaxReturnUploadQuestion = ({
  program,
  onComplete,
  getQuestionProps,
  setEditMode,
  saveAnswers,
  documents,
}: TaxReturnUploadQuestionProps) => {
  const classes = useStyles();
  const { client } = useContext(Auth0FeatureContext);
  const { company } = useContext(CompanyContext);
  const { companyStore } = useCommonStores();
  const [manualGrossInput, setManualGrossInput] = useState<{
    [id: number]: boolean;
  }>({});
  const [files, setFiles] = useState<Record<string, (FileHandle | Document)[]>>(
    {},
  );
  const [activeQuestion] = useState<CmsQuestionData>(getQuestionProps);
  const [isHelpDrawerOpen, setIsHelpDrawerOpen] = useState(false);
  const [showEncryptedModal, setShowEncryptedModal] = useState(false);
  const [currentEncryptedFile, setCurrentEncryptedFile] =
    useState<EncryptedFileProps>({ questionId: null, fileHandle: null });
  const [isConfirmedCheckbox, setIsConfirmedCheckbox] =
    useState<boolean>(false);
  const [uploadedDocuments, setUploadedDocuments] = useState<
    Record<string, Document[]>
  >({});
  const [deleteLoading, setDeleteLoading] = useState<{
    [id: number]: boolean;
  }>({});
  // TODO: update this so that if a taxReturn is removed in the UI the state gets updated to reflect that
  const [existingTaxReturnYears, setExistingTaxReturnYears] = useState<
    number[]
  >([]);
  const [autoCollapse, setAutoCollapse] = useState<boolean>(false);

  // check which years we already have FEDERAL_CORPORATE_INCOME_TAX_RETURN docs for
  // and suppress tax return questions for those years
  const questionIDsToSuppress = taxInfoQuestionIds
    .filter((q) => existingTaxReturnYears.includes(q.year))
    .flatMap((q) => [q.uploadQuestionId, q.manualQuestionId]);

  const hardcodedSubQuestionIds = [
    GraphCmsQuestionIdEnum.GROSS_RECEIPTS_2016,
    GraphCmsQuestionIdEnum.GROSS_RECEIPTS_2017,
    GraphCmsQuestionIdEnum.GROSS_RECEIPTS_2018,
    GraphCmsQuestionIdEnum.GROSS_RECEIPTS_2019,
    GraphCmsQuestionIdEnum.GROSS_RECEIPTS_2020,
    GraphCmsQuestionIdEnum.GROSS_RECEIPTS_2021,
    GraphCmsQuestionIdEnum.GROSS_RECEIPTS_2022,
    GraphCmsQuestionIdEnum.FEDERAL_INCOME_TAX_RETURN_2016,
    GraphCmsQuestionIdEnum.FEDERAL_INCOME_TAX_RETURN_2017,
    GraphCmsQuestionIdEnum.FEDERAL_INCOME_TAX_RETURN_2018,
    GraphCmsQuestionIdEnum.FEDERAL_INCOME_TAX_RETURN_2019,
    GraphCmsQuestionIdEnum.FEDERAL_INCOME_TAX_RETURN_2020,
    GraphCmsQuestionIdEnum.FEDERAL_INCOME_TAX_RETURN_2021,
    GraphCmsQuestionIdEnum.FEDERAL_INCOME_TAX_RETURN_2022,
  ];

  const applicableSubQuestions = (activeQuestion.subQuestions ?? [])
    .filter((q) => !questionIDsToSuppress.includes(q.id))
    // .filter((q) => taxYearFiltering(q, program.taxYear, company, hardcodedSubQuestionIds));
    .filter((q) =>
      taxYearFilteringTaxReturnUpload(
        q,
        program.taxYear,
        company,
        hardcodedSubQuestionIds,
      ),
    );

  const allQuestionsAnswered = applicableSubQuestions.every((q, i) => {
    // answers are in pairs, ie [fileUpload, grossReceipts, fileUpload, grossReceipts, etc]
    // for each question pair, ensure one of the question (i or i + 1) has an answer

    const fileUploadAnswer = files[q.id] ?? [];
    const grossReceiptsAnswer =
      applicableSubQuestions[i + 1]?.answerValue ?? -1;
    const numericalGrossReceiptsAnswer = Number.isInteger(grossReceiptsAnswer)
      ? grossReceiptsAnswer
      : -1;
    if (i % 2 === 0) {
      return fileUploadAnswer.length > 0 || numericalGrossReceiptsAnswer >= 0;
    }

    return true;
  });

  const onContinue = () => {
    onComplete(activeQuestion);
    setEditMode(false);
  };

  const uploadTaxReturn = async (
    questionId: string,
    fileHandler: FileHandle,
  ) => {
    // FIXME: UploadCompanyDocument has no server end point.
    // Refactor to use UploadCompanyDocuments
    const uploadCompanyDocument = companyStore.accessToken
      ? client.UploadCompanyDocumentPublic(
          companyStore.accessToken,
          fileHandler,
          fileHandler.name,
          program
            ? `${Programs[program?.name].display} ${
                program?.taxYear
              } Expense Classification Document`
            : '',
          program !== null ? String(program?.id) : undefined,
        )
      : client.UploadCompanyDocument(
          company.id,
          fileHandler,
          fileHandler.name,
          program
            ? `${Programs[program?.name].display} ${
                program?.taxYear
              } Expense Classification Document`
            : '',
          program !== null ? String(program?.id) : undefined,
        );

    const filePromise: Promise<{
      questionId: string;
      fileHandler: FileHandle;
      response: MSClientResponse<{ document: Document }>;
    }> = uploadCompanyDocument
      .then((response) => ({ questionId, fileHandler, response }))
      .catch((error) => error);

    return filePromise.then((result) => {
      let hasUploaded = true;
      let documentId;

      if (result.response.data) {
        const { document } = result.response.data;
        if (getQuestionProps?.subQuestions) {
          const question = getQuestionProps.subQuestions.find(
            (subQuestion) => subQuestion.id === questionId,
          );
          if (question) {
            if (Array.isArray(question.answerValue)) {
              question.answerValue.push(document.id.toString());
            } else {
              question.answerValue = [];
              question.answerValue.push(document.id.toString());
            }

            documentId = document.id;
          } else {
            fileHandler.setError(
              result.response.errorMsg || GENERIC_VISUAL_ERROR,
            );
            hasUploaded = false;
          }
        }
      }

      return { hasUploaded, documentId };
    });
  };

  const onFileAdded = async (
    questionId: string,
    fileHandle: FileHandle,
    bypassFileEncryptionValidation: boolean,
  ) => {
    let promise: Promise<void> = Promise.resolve();
    const fileSizeWithinLimits = isFileSizeWithinLimits(fileHandle.file);
    const fileEncrypted = await isFileEncrypted(fileHandle);

    if (!fileSizeWithinLimits) {
      return;
    }

    if (!bypassFileEncryptionValidation && fileEncrypted) {
      setCurrentEncryptedFile({ questionId, fileHandle });
      setShowEncryptedModal(true);
      return;
    } else {
      fileHandle.setError('');
    }

    promise = uploadTaxReturn(questionId, fileHandle).then((success) => {
      // store db document id to fileHandle
      fileHandle.documentId = success.documentId;
    });

    promise.then(() => {
      const questionFileList = files[questionId] ? [...files[questionId]] : [];
      const updatedFiles = {
        ...files,
        [questionId]: [...questionFileList, fileHandle],
      };
      setFiles(updatedFiles);

      // update answers if file added
      if (saveAnswers) {
        saveAnswers();
      }
    });
  };

  const onFileRemoved = (questionId: string, file: FileHandle) => {
    const questionFileList = files[questionId] ? [...files[questionId]] : [];
    const fileIndex = questionFileList.findIndex(
      (f) => (f as FileHandle).vid === file.vid,
    );
    if (fileIndex >= 0) {
      questionFileList.splice(fileIndex, 1);
    }
    const updatedFiles = {
      ...files,
      [questionId]: questionFileList,
    };
    setFiles(updatedFiles);

    // remove documentId from answerValue array
    if (getQuestionProps?.subQuestions) {
      const question = getQuestionProps.subQuestions.find(
        (subQuestion) => subQuestion.id === questionId,
      );
      if (question && Array.isArray(question?.answerValue)) {
        if (file.documentId) {
          const documentId = file.documentId;
          const removeId = question.answerValue.findIndex(
            (id) => Number(id) === documentId,
          );

          if (removeId !== -1) {
            question.answerValue.splice(removeId, 1);
          }

          companyStore.accessToken
            ? client.DeleteDocumentPublic(
                companyStore.accessToken,
                file.documentId,
              )
            : client.DeleteDocument(file.documentId);
        }
      }
    }

    // update answers if file removed
    if (saveAnswers) {
      saveAnswers();
    }
  };

  const toggleManualInputState = (itemId: number) => {
    setManualGrossInput((prev) => ({
      ...prev,
      [itemId]: !prev[itemId],
    }));
  };

  useEffect(() => {
    if (documents) {
      setExistingTaxReturnYears(
        documents
          .filter(
            (doc) =>
              doc.documentType ===
              DocumentTypeEnum.FEDERAL_CORPORATE_INCOME_TAX_RETURN,
          )
          .map((doc) => doc.taxYear ?? -1),
      );
    }
  }, [documents]);

  useEffect(() => {
    if (activeQuestion && activeQuestion.subQuestions) {
      const filterAnswerValues = activeQuestion.subQuestions.filter((s) =>
        Array.isArray(s.answerValue),
      );
      const uploadedDocs: { [key: string]: Document[] } = {};

      if (applicableSubQuestions.length === 0) {
        setIsConfirmedCheckbox(true);
        setAutoCollapse(true);
        onContinue();
      }

      if (documents) {
        filterAnswerValues.forEach((question) => {
          if (Array.isArray(question.answerValue)) {
            question.answerValue.forEach((answerId) => {
              documents.forEach((doc) => {
                const docIdString = doc.id.toString();

                if (docIdString === answerId) {
                  if (uploadedDocs[question.id]) {
                    uploadedDocs[question.id] = [
                      ...uploadedDocs[question.id],
                      doc,
                    ];
                  } else {
                    uploadedDocs[question.id] = [doc];
                  }
                }
              });
            });
          }
        });

        setUploadedDocuments(uploadedDocs);
        setFiles(uploadedDocs);
      }
    }
  }, [
    activeQuestion,
    documents,
    applicableSubQuestions.length,
    existingTaxReturnYears,
  ]);

  // this is to delete documents that were already uploaded
  // if customer had already previously visited the company details step
  const deleteDocument = async (
    docId: number,
    questionId: string,
    index: number,
  ) => {
    setDeleteLoading((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));

    const deleteDocument = companyStore.accessToken
      ? client.DeleteDocumentPublic(companyStore.accessToken, docId)
      : client.DeleteDocument(docId);

    await deleteDocument.then(() => {
      const updateDocumentList = uploadedDocuments[questionId].filter(
        (doc) => doc.id !== docId,
      );

      const updatedDocList = {
        ...uploadedDocuments,
        [questionId]: [...updateDocumentList],
      };

      setUploadedDocuments(updatedDocList);
      setDeleteLoading((prev) => ({
        ...prev,
        [index]: !prev[index],
      }));

      // remove documentId from answerValue array
      if (getQuestionProps?.subQuestions) {
        const question = getQuestionProps.subQuestions.find(
          (subQuestion) => subQuestion.id === questionId,
        );
        if (question && Array.isArray(question?.answerValue)) {
          const removeId = question.answerValue.findIndex(
            (id) => Number(id) === docId,
          );

          if (removeId !== -1) {
            question.answerValue.splice(removeId, 1);
          }
        }
      }

      // update answers if file removed
      if (saveAnswers) {
        saveAnswers();
      }
    });
  };

  return activeQuestion && activeQuestion.subQuestions ? (
    <>
      <Card noMargin>
        <SubQuestion
          key={activeQuestion.id}
          title={activeQuestion.text}
          subtitle={activeQuestion.subtitle}
          onHelpLinkClick={() => setIsHelpDrawerOpen(true)}
          fontSize={15}
          paddingTopBottom={16}
        />
        <Flex direction='column' padding={[24, 24, 8, 24]}>
          {applicableSubQuestions.length > 0 &&
            applicableSubQuestions.map((question, index) => {
              const onChangeHandler = (value: AnswerValueType) => {
                question.answerValue = value;
              };

              // if manual input has value, show manual input state
              if (
                question.answerType === 'currency' &&
                !manualGrossInput[index]
              ) {
                if (
                  question.answerValue !== undefined &&
                  question.answerValue !== null &&
                  question.answerValue !== ''
                ) {
                  toggleManualInputState(index);
                }
              }

              return (
                <Flex
                  key={`${question.id}-${index}`}
                  direction='column'
                  padding={
                    question.answerType === 'currency' ? [0, 0, 24, 0] : 0
                  }
                >
                  {question.answerType === 'currency' ? (
                    <Card noBoxShadow noMargin key={`${question.id}-${index}`}>
                      <Expandable expand={manualGrossInput[index]}>
                        {manualGrossInput[index] ? (
                          <Flex
                            padding={[8, 24, 24, 24]}
                            alignItems='flex-end'
                            justifyContent='space-between'
                            className={classes.manualInputField}
                          >
                            <Flex gap={16} direction='column'>
                              <SurveyQuestion
                                label={question.text}
                                answerType={question.answerType as AnswerType}
                                onChange={onChangeHandler}
                                onBlur={() => saveAnswers && saveAnswers()}
                                placeholder={question.placeholder}
                                answerValue={question.answerValue}
                                withCard={false}
                                helperText='Enter your gross receipts amount for this year'
                              />
                              <Alert
                                text={
                                  'If you are a pre-revenue startup and do not have any taxable income, please enter gross receipts as $0'
                                }
                                type='info'
                                variant='card'
                              />
                            </Flex>
                            {question.answerValue === undefined ? (
                              <Button
                                label='Cancel'
                                variant='inline'
                                onClick={() => toggleManualInputState(index)}
                              />
                            ) : (
                              <Button
                                label='Remove'
                                variant='inline'
                                onClick={() => {
                                  onChangeHandler('');
                                  saveAnswers && saveAnswers();
                                  toggleManualInputState(index);
                                }}
                              />
                            )}
                          </Flex>
                        ) : (
                          <></>
                        )}
                      </Expandable>
                      {!manualGrossInput[index] && (
                        <Alert
                          text={
                            manualGrossInput[index]
                              ? ''
                              : 'You can add your gross receipts and QREs for this years manually instead'
                          }
                          type='info'
                          variant='in_card'
                          actions={{
                            onClick: () => toggleManualInputState(index),
                            text: manualGrossInput[index]
                              ? 'Cancel'
                              : 'Add gross receipts and QREs',
                          }}
                        />
                      )}
                    </Card>
                  ) : (
                    <>
                      <SurveyQuestion
                        tooltip="Upload this year's complete federal return. Ensure it matches your business type (e.g., 1120 for C Corps)."
                        answerType={question.answerType as AnswerType}
                        placeholder={question.placeholder}
                        answerValue={question.answerValue}
                        text={question.text}
                        withCard={false}
                        acceptedFiletypes={[
                          FileTypes.CSV,
                          FileTypes.PDF,
                          FileTypes.XLS,
                          FileTypes.XLSX,
                        ]}
                        maxFileSize={UPLOAD_FILE_SIZE_LIMIT}
                        onFileAdded={async (file: FileHandle) => {
                          await onFileAdded(question.id, file, false);
                        }}
                        onFileRemoved={(file: FileHandle) =>
                          onFileRemoved(question.id, file)
                        }
                        onFileCancelled={(file: FileHandle) => {
                          onFileRemoved(question.id, file);
                        }}
                      />
                      {uploadedDocuments &&
                        uploadedDocuments[question.id] &&
                        uploadedDocuments[question.id].map((doc, index) => {
                          return (
                            <Flex
                              padding={24}
                              key={`${doc.id}-${index}`}
                              className={classes.uploadedDocs}
                              alignItems='center'
                              justifyContent='space-between'
                              gap={24}
                            >
                              <Flex.Cell>
                                <Text
                                  text={doc.name}
                                  color={Color.neutral._80}
                                />
                                <Text
                                  size={11}
                                  text='(Previously uploaded)'
                                  variant='italic'
                                  color={Color.neutral._60}
                                />
                              </Flex.Cell>
                              <Button
                                onClick={() =>
                                  deleteDocument(doc.id, question.id, doc.id)
                                }
                                label={
                                  <Text
                                    color={Color.semantic.$error50}
                                    size={13}
                                    variant='medium'
                                    text='Remove'
                                  />
                                }
                                variant='tertiary'
                                small
                                loading={deleteLoading[doc.id]}
                              />
                            </Flex>
                          );
                        })}
                    </>
                  )}
                </Flex>
              );
            })}
        </Flex>
        <Flex padding={[0, 80, 8, 24]} direction='column' gap={12}>
          {autoCollapse && (
            <Text size={18} status='in_process'>
              We have on record that you&apos;ve uploaded your tax returns(s)
              already or you are not required to upload any. Please click
              continue to proceed.
            </Text>
          )}
          <Checkbox
            dataTestId='confirmation-checkbox'
            onChange={() => setIsConfirmedCheckbox(!isConfirmedCheckbox)}
            options={[
              {
                checked: isConfirmedCheckbox,
                text: `I hereby confirm and review that I have either uploaded or entered all correct values for each tax year in gross receipts and QREs.`,
                value: 'confirmed',
              },
            ]}
          />
        </Flex>
        <CardFooter
          dataTestId={'tax-form-upload-question--continue'}
          primaryCtaLabel='Continue'
          primaryCtaDisabled={!isConfirmedCheckbox || !allQuestionsAnswered}
          primaryOnClick={() => onContinue()}
        />
        <SideDrawer
          show={isHelpDrawerOpen}
          title={'Where to find this information'}
          closeToggle={() => setIsHelpDrawerOpen(false)}
          drawerActions={<></>}
          drawerContent={<TotalIncomeGrossReceiptsHelpDrawer />}
        />
      </Card>
      <Modal
        showModal={showEncryptedModal}
        closeToggle={() => setShowEncryptedModal(false)}
        maxWidth={536}
      >
        <Flex direction='column' alignItems='center' gap={16} padding={24}>
          <AttentionNeeded />
          <Text
            variant='bold'
            size={23}
            text='Does the file you uploaded have a password?'
          />
          <Text variant='regular' size={15} tag='span'>
            <ul>
              <li>
                If Yes - please remove the password protection and re-upload
              </li>
              <li>If No - you may proceed</li>
            </ul>
          </Text>
          <Flex gap={16} justifyContent='flex-end'>
            <Button
              variant='outlined'
              label='Remove Password'
              onClick={() => setShowEncryptedModal(false)}
              dataTestId={'encrypted-modal-secondary-action'}
            />
            <Button
              label={'Proceed with Upload'}
              onClick={() => {
                if (
                  currentEncryptedFile &&
                  currentEncryptedFile.questionId &&
                  currentEncryptedFile.fileHandle
                ) {
                  onFileAdded(
                    currentEncryptedFile.questionId,
                    currentEncryptedFile.fileHandle,
                    true,
                  );
                  setShowEncryptedModal(false);
                }
              }}
              dataTestId={'encryoted-modal-primary-action'}
            />
          </Flex>
        </Flex>
      </Modal>
    </>
  ) : (
    <></>
  );
};

export default TaxReturnUploadQuestion;
